.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* width */
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  background: #e5dcd5;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 50vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5cdb9;
  border-radius: 50vh;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.card-container {
  perspective: 1000px;
}

.card {
  width: 300px;
  height: 340px;
  
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.card-face-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 24px;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  transform: rotateY(180deg);
}

.card:hover {
  transform:rotateY(-180deg)
}
.container {
  width: 150px;
  height: 150px;
  display: grid;
  overflow: hidden;
  margin: 0;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}

.item {
  background: skyblue;
  border-radius: 100%;
}
